import style from "../style/footer.module.css";

export default function Header(props){
    return (
        <div className={style.head}>
            <img src="styling/bg.png"/>
            <h1>Festa privata ad invito</h1>
            <div>Presentarsi SOLO se inseriti nella lista degli invitati</div>
        </div>
    )
}

export function Footer(props){
    return (
        <div className={style.foot}>
            This website was crafted with 💗 by <a href="https://hypedmike.github.io">Michele Saladino</a>
        </div>
    )
}