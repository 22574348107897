import logo from './logo.svg';
import './App.css';
import RegistrationForm from './shared/regform';
import Header, { Footer } from './shared/header';
import { Stack } from '@mui/material';

function App() {
  return (
    <div className="App">
      <Header />
        <Stack style={{minHeight: "80vh"}} justifyContent={"center"} alignItems={"stretch"}>
          <RegistrationForm />
        </Stack>
      <Footer />
    </div>
  );
}

export default App;
