import { Button, Checkbox, Stack, TextField } from "@mui/material";
import { useState } from "react";
import style from "../style/registration.module.css";
import DrawingCanvas from "./drawingCanvas";
import PopUP from "./popup";

export default function RegistrationForm(props){

    const [loading, setLoading] = useState(false);
    const [outcome, setOutcome] = useState(0);

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [fis, setFis] = useState("");
    const [year, setYear] = useState(null);
    const [terms, setTerms] = useState(false);

    const [signature, setSignature] = useState(null);

    const send = () => {
        setOutcome(0);
        /*let verify = true;
        if(name == ""){
            verify = false;
            alert("Name is not valid");
        }
        if(surname == ""){
            verify = false;
            alert("Surname is not valid");
        }
        if(fis.length == undefined || fis.length != 16){
            verify = false;
            alert("Codice fiscale is not valid");
        }
        if(year > 2020 || year < 1990){
            verify = false;
            alert("Year is not valid");
        }
        if(terms != true){
            verify = false;
            alert("Accept terms first");
        }*/
        setLoading(true)
        setTimeout(() => {
            setOutcome(1)
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }, 2000)
    }

    const saveSign = (e) => {
        setSignature(e)
    }



    return (
        <Stack justifyContent={"center"} alignItems="center" className={style.body}>
            {
                loading && <PopUP outcome={outcome} text={"In attesa di risposta dal server..."}/>
            }
            <input className={style.textfield} placeholder="Nome" value={name} onChange={(e) => setName(e.target.value)}></input>
            <input className={style.textfield} placeholder="Cognome" value={surname} onChange={(e) => setSurname(e.target.value)}></input>
            <select placeholder="sesso" className={style.textfield}>
                <option>Maschio</option>
                <option>Femmina</option>
            </select>
            <input className={style.textfield} placeholder="Codice Fiscale"  value={fis} onChange={(e) => setFis(e.target.value)}></input>
            <input className={style.textfield} placeholder="Data di nascita"  value={fis} onChange={(e) => setFis(e.target.value)}></input>
            <input className={style.textfield} placeholder="Comune di nascita"  value={fis} onChange={(e) => setFis(e.target.value)}></input>
            <input className={style.textfield} placeholder="Numero documento"  value={fis} onChange={(e) => setFis(e.target.value)}></input>
            <input className={style.textfield} placeholder="Residenza"  value={fis} onChange={(e) => setFis(e.target.value)}></input>
            <input className={style.textfield} placeholder="Domicilio"  value={fis} onChange={(e) => setFis(e.target.value)}></input>
            <input className={style.textfield} placeholder="Anno di nascita" type={"number"}  value={year ? year : ""} onChange={(e) => setYear(e.target.value)}></input>
            
            <DrawingCanvas save={saveSign}/>
            {
                signature ? <img src={signature} width="300px" height={"300px"} /> : null
            }
            <Stack>
                <div style={{color: "white", margin: "10px"}}>
                    <input type={"checkbox"} className={style.checkbox} value={terms} onChange={(e) => setTerms(e.target.value)}></input>
                    Dichiaro:
                    <ul>
                        <li>di aver letto i <a href="/styling/terms.pdf" target="_blank" style={{color: "white"}}>termini e condizioni</a></li>
                        <li>di aver inserito correttamente la mia firma nel riquadro sottostante</li>
                        <li>di accettare che la mia firma venga automaticamente inserita nel documento <a href="/styling/terms.pdf" target="_blank" style={{color: "white"}}>riguardante i termini e condizioni</a></li>
                    </ul>
                </div>
            </Stack>
            
            <button onClick={send} className={style.button} disabled={loading}>Registrati</button>
        </Stack>
    )
}