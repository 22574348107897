import { useEffect, useState } from "react";
import style from "../style/popup.module.css";

/**
 * 
 * @param {Int} props 0 -> null, 1 -> success, 2 -> error 
 * @returns 
 */
export default function PopUP(props){

    const [outcome, setOutcome] = useState(props.outcome);

    useEffect(() => {
        setOutcome(props.outcome);
    }, [props.outcome])

    return (
        <div className={style.module}>
            <div>
                {outcome == 0 ? <div>{props.text}</div> : null}
                {outcome == 1 ? <div>Success</div> : null}
                {outcome == 2 ? <div>Error</div> : null}
            </div>
        </div>
    )
}